import { createContext } from 'react';
import { UserInfo, Module } from 'types';

export const AuthCtx = createContext<{
  userInfo?: UserInfo;
  refreshUser: () => Promise<void>;
  availableModules: Module[];
}>({
  userInfo: undefined,
  refreshUser: async () => {},
  availableModules: [],
});
