import React, { useContext } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import { Jumbotron } from 'components/jumbotron/jumbotron';
import { AuthCtx } from 'components/auth/auth-ctx';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    content: {
      padding: '50px 0',
    },
    notFound: {
      padding: '50px 30px',
      backgroundColor: '#FDEEED',

      borderRadius: 6,
    },
    notFoundTitle: {
      marginBottom: 15,
    },
  })
);

export const NotFound = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { userInfo } = useContext(AuthCtx);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Jumbotron
          title={
            userInfo
              ? `${formatMessage({
                  id: 'jumbotron.loggedIn',
                  defaultMessage: 'Vítejte',
                })}, ${userInfo.fullName}`
              : `${formatMessage({
                  id: 'jumbotron.notLoggedIn',
                  defaultMessage: 'Vstupní portál',
                })}`
          }
        />
        <Grid container className={classes.content}>
          <Grid item xs={1} md={3} />
          <Grid item xs={10} md={6} className={classes.notFound}>
            <Typography
              variant="h5"
              className={classes.notFoundTitle}
              align="center"
            >
              {formatMessage({
                id: 'notFound.title',
                defaultMessage: 'Něco se pokazilo',
              })}
            </Typography>
            <Typography variant="body1" align="center">
              {formatMessage({
                id: 'notFound.message',
                defaultMessage: 'ÚDAUK',
              })}
            </Typography>
          </Grid>
          <Grid item xs={1} md={3} />
        </Grid>
      </Grid>
    </Grid>
  );
};
