import { useMemo } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { Loader } from 'components/loader/loader';
import useFetch from 'hooks/use-fetch';
import { Text } from 'types';
import { TextLocation } from 'enums';
import { parseHtml } from 'utils/parse-html';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 300,
      backgroundColor: '#626262',
      color: 'white',
      flexShrink: 0,
      zIndex: 1000,
      paddingBottom: 50,
    },
    header: {
      paddingTop: 25,
    },
    link: {
      color: 'white',
    },
    anchor: {
      color: '#FFD08F',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);

export function Footer() {
  const classes = useStyles();

  const { data: contacts, loading: contactsLoading } = useFetch<Text>(
    `/badatelna/api/paragraph?location=${TextLocation.CONTACTS}`
  );
  const contactsHtml = contacts?.text ?? '';
  const contactsProcessed = useMemo(
    () => parseHtml(contactsHtml, () => {}, classes),
    [contactsHtml, classes]
  );
  const { data: information, loading: informationLoading } = useFetch<Text>(
    `/badatelna/api/paragraph?location=${TextLocation.INFORMATION}`
  );
  const informationHtml = information?.text ?? '';
  const informationProcessed = useMemo(
    () => parseHtml(informationHtml, () => {}, classes),
    [informationHtml, classes]
  );
  const { data: badatelna, loading: badatelnaLoading } = useFetch<Text>(
    `/badatelna/api/paragraph?location=${TextLocation.BADATELNA}`
  );
  const badatelnaHtml = badatelna?.text ?? '';
  const badatelnaProcessed = useMemo(
    () => parseHtml(badatelnaHtml, () => {}, classes),
    [badatelnaHtml, classes]
  );

  return (
    <Grid container className={classes.root}>
      <Grid item xs={1} md={2} />
      <Grid item xs={10} md={8}>
        <Grid container>
          <Grid item sm={3} xs={12}>
            {contactsLoading ? (
              <Loader />
            ) : (
              <Typography
                variant="body1"
                align="justify"
                className={classes.header}
              >
                {contactsProcessed}
              </Typography>
            )}
          </Grid>
          <Grid item sm={1} xs={12} />

          <Grid item sm={3} xs={12}>
            {informationLoading ? (
              <Loader />
            ) : (
              <Typography
                variant="body1"
                align="justify"
                className={classes.header}
              >
                {informationProcessed}
              </Typography>
            )}
          </Grid>
          <Grid item sm={1} xs={12} />

          <Grid item sm={3} xs={12}>
            {badatelnaLoading ? (
              <Loader />
            ) : (
              <Typography
                variant="body1"
                align="justify"
                className={classes.header}
              >
                {badatelnaProcessed}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} md={2} />
    </Grid>
  );
}
