import { SelectField } from 'components/select-field/select-field';
import React, { useContext } from 'react';
import { I18nCtx } from 'components/i18n/i18n-context';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  selectWrapper: {
    margin: '0 10px',
  },
  select: {
    maxHeight: 40,
    padding: '12px 16px',
    minWidth: 20,
  },
}));

const LanguageOptions = [
  {
    id: 'cs',
    label: 'CZ',
  },
  {
    id: 'en',
    label: 'EN',
  },
];

export function LanguageChooser() {
  const i18nctx = useContext(I18nCtx);
  const classes = useStyles();

  return (
    <div className={classes.selectWrapper}>
      <SelectField
        name="language"
        items={LanguageOptions}
        onChange={(o) => i18nctx.handleSelectLanguage(o)}
        value={i18nctx.locale}
        additionalClasses={{
          select: classes.select,
        }}
      />
    </div>
  );
}
