import React from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles, Theme } from '@material-ui/core';

interface ModuleRedirectProps {
  text: string;
  href: string;
  style?: React.CSSProperties;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'transform 0.3s',
    '&:hover': {
      transform: 'scale(1.2)',
      transition: 'transform 0.3s',
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
    fontSize: 16,
    fontWeight: 600,
    padding: '30px 20px',
    minWidth: 120,
    marginBottom: 15,
  },
}));

export const ModuleRedirect = ({ text, href, style }: ModuleRedirectProps) => {
  const classes = useStyles();

  return (
    <a href={process.env.PUBLIC_URL + `${href}`}>
      <Card className={classes.root} style={style} elevation={0}>
        {text}
      </Card>
    </a>
  );
};
