import { get, isEmpty, trimEnd } from 'lodash';
import decode from 'jwt-decode';

import { ErrorCode, errorCodeMap } from '../types';

export const defaultOptions = {
  credentials: 'same-origin' as 'same-origin',
};

/**
 * Error shape from BE.
 */
export interface ApiError {
  code: ErrorCode;
  status: number;
  details: any;
}

/**
 * Error populated with message.
 */
export interface ErrorMessage {
  code: ErrorCode;
  message: string;
  details: any;
}

interface FetchType {
  method?: string;
  params?: any;

  body?: any;
  headers?: Headers;

  errorHandler?: (error: ApiError) => ErrorMessage;
}

/**
 * If no message formatter is provided, returned plain stringified error code.
 */
export function getErrorMessage(error: ApiError) {
  return {
    code: error.code || error.status.toString(),
    message: errorCodeMap[error.code ?? error.status.toString()],
    details: error.details,
  };
}

/**
 * Encode query params.
 *
 * @param obj
 */
export const serialize = (obj: any = {}) =>
  Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&');

/**
 * Error handler.
 *
 * @param response
 * @param errorHandler
 */
async function handleErrors(
  response: Promise<Response>,
  errorHandler: (error: ApiError) => ErrorMessage
): Promise<Response> {
  const r = await response;

  if (r.redirected) {
    window.location.reload(true);
  }

  // something went wrong
  if (!r.ok) {
    // Not authorized
    if (r.status === 401) {
      localStorage.removeItem('token');
      window.location.href = 'Shibboleth.sso/Login?target=/';
    }
    const errorJson = await r.json();

    // custom error
    throw errorHandler({
      code: errorJson.code,
      details: errorJson.details,
      status: errorJson.status,
    });
  }

  const token = r?.headers.get('Bearer');

  if (token) {
    localStorage.setItem('token', token);
  }

  return r;
}

/**
 * Fetch utility.
 */
const customFetch = (url: string, { params, ...rest }: FetchType = {}) => {
  const options = { ...defaultOptions, ...rest };
  let headers = get(options, 'headers') || new Headers();

  const queryString = serialize(params);
  const questionMark = isEmpty(queryString) ? '' : '?';

  const context = process.env.PUBLIC_URL;

  const token = localStorage.getItem('token');
  if (token) {
    const { exp } = decode<{ exp: number }>(token);
    const now = Math.floor(Date.now() / 1000); // number of seconds since epoch

    if (now < exp) {
      headers.set('Authorization', `Bearer ${token}`);
    } else {
      localStorage.removeItem('token');
    }
  }

  return handleErrors(
    fetch(`${trimEnd(context, '/')}${url}${questionMark}${queryString}`, {
      ...options,
      headers,
    }),
    options.errorHandler ?? getErrorMessage
  );
};

export default customFetch;
