import React from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { AuthCtx } from 'components/auth/auth-ctx';
import { useAuth } from 'components/auth/auth-hook';
import { Header } from 'components/header/header';
import { Footer } from 'components/footer/footer';
import { useI18n, I18nCtx } from 'components/i18n/i18n-context';

import { Homepage } from 'modules/homepage/homepage';
import { NotFound } from 'modules/404/404';

export function App() {
  const { userInfo, refreshUser, availableModules } = useAuth();
  const i18ctx = useI18n();

  return (
    <AuthCtx.Provider value={{ userInfo, refreshUser, availableModules }}>
      <I18nCtx.Provider value={i18ctx}>
        <IntlProvider locale={i18ctx.locale} messages={i18ctx.messages}>
          <Router basename={process.env.PUBLIC_URL}>
            <Header />
            <div className="content">
              <Switch>
                <Route exact path="/">
                  <Homepage />
                </Route>
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
            </div>
            <Footer />
          </Router>
        </IntlProvider>
      </I18nCtx.Provider>
    </AuthCtx.Provider>
  );
}
