import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'classnames';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
  Button,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { LanguageChooser } from 'components/i18n/language-chooser';
import { useIntl } from 'react-intl';
import { AuthCtx } from 'components/auth/auth-ctx';
import { I18nCtx } from 'components/i18n/i18n-context';

export const loginRedirect = (target: string = '/') => {
  window.location.href = `${window.location.origin}/Shibboleth.sso/Login?target=${target}`;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxShadow: theme.shadows[1],
      backgroundColor: 'white',
      position: 'fixed',
      top: 0,
      zIndex: 1000,
    },
    logoWrapper: {
      width: '100%',
      height: 80,
      display: 'flex',
    },
    menuWrapper: {
      width: '100%',
      height: 80,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    buttonsWrapper: {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',

      '& > *': {
        '@media(min-width: 1280px)': {
          margin: `${theme.spacing(2)}px 5px`,
        },
        '@media(min-width: 1400px)': {
          margin: `${theme.spacing(2)}px 10px`,
        },
      },
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      cursor: 'pointer',
    },
    text: {
      textTransform: 'initial',
      textDecoration: 'none',
    },
    defaultText: {
      fontWeight: 600,
      fontSize: 14,
      color: 'black',
      textTransform: 'initial',
    },
    primaryText: {
      fontWeight: 'bold',
      color: 'white',
      textTransform: 'initial',
    },
    redText: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark,
      textTransform: 'initial',
    },
    primaryListItem: {
      backgroundColor: theme.palette.primary.main,

      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    logo: {
      maxWidth: '100%',
      width: 'auto',
      height: 'auto',
      maxHeight: 80,
    },
    menuIcon: {
      cursor: 'pointer',
    },
    drawer: {},
    drawerHeader: {},
    list: {
      width: 300,
    },
    cartWrapper: {
      padding: '0px 5px',
    },
  })
);

export function Header() {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { userInfo } = useContext(AuthCtx);

  const [openDrawer, setOpenDrawer] = useState(false);

  const history = useHistory();

  const { locale } = useContext(I18nCtx);

  return (
    <Grid container className={classes.root}>
      <Grid item md={2} xs={6}>
        <Typography component="nav" className={classes.logoWrapper}>
          <div className={classes.link} onClick={() => history.push('/')}>
            <img
              src={`${process.env.PUBLIC_URL}/archiv.png`}
              alt="logo"
              className={classes.logo}
            />
          </div>
        </Typography>
      </Grid>
      <Grid item md={10} xs={6}>
        <div className={classes.menuWrapper}>
          <div className={classes.buttonsWrapper}>
            <Hidden mdDown>
              <Button
                component="a"
                href={
                  locale === 'en'
                    ? 'https://udauk.cuni.cz/ARCHEN-1.html'
                    : 'https://udauk.cuni.cz/ARCH-1.html'
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.defaultText}
                >
                  Web ÚDAUK
                </Typography>
              </Button>
              {!userInfo && (
                <Button
                  component="span"
                  variant={'contained'}
                  color={'primary'}
                  onClick={() => loginRedirect()}
                >
                  <Typography
                    component="span"
                    variant="body2"
                    className={clsx({
                      [classes.primaryText]: true,
                    })}
                  >
                    {formatMessage({
                      id: 'button.signIn',
                      defaultMessage: 'Přihlásit se',
                    })}
                  </Typography>
                </Button>
              )}
              <div className={classes.cartWrapper} />
              <LanguageChooser />
            </Hidden>
            <Hidden lgUp>
              <IconButton onClick={() => setOpenDrawer(true)}>
                <MenuIcon />
              </IconButton>

              <Drawer
                className={classes.drawer}
                variant="temporary"
                anchor="right"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
              >
                <div className={classes.drawerHeader}>
                  <IconButton onClick={() => setOpenDrawer(false)}>
                    <ChevronRightIcon />
                  </IconButton>
                  <LanguageChooser />
                </div>
              </Drawer>
            </Hidden>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
