import { BadatelnaLevel, RoleName } from 'enums';
import { UserInfo, Module } from 'types';

export function getAvailableModules(userInfo?: UserInfo) {
  let modules: Module[] = [
    {
      title: 'Badatelna',
      backgroundColor: '#EEF0FF',
      href: '/badatelna',
    },
  ];

  if (userInfo?.existsInElza) {
    modules.unshift({
      title: 'Elza',
      backgroundColor: '#FEFBE8',
      href: '/elza',
    });
  }

  if (
    userInfo &&
    [
      RoleName.ARCHIVIST,
      RoleName.LEAD_ARCHIVIST,
      RoleName.DIGITAL_CONTENT_ADMIN,
      RoleName.AIS_ADMIN,
    ].includes(userInfo?.roleName)
  ) {
    modules.unshift({
      title: 'ESM',
      backgroundColor: '#F2E1E6',
      href: '/esm',
    });
  }

  if (userInfo?.existsInVyber) {
    modules.unshift({
      title: 'Výber',
      backgroundColor: '#FCEDEC',
      href: '/vyber',
    });
  }

  if (userInfo?.badatelnaLevel === BadatelnaLevel.ADMIN) {
    modules.push({
      title: 'Správa badatelny',
      backgroundColor: '#F2E1E6',
      href: '/badatelna/admin',
    });
  }
  return modules;
}
