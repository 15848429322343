import React, { useContext, useMemo } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import { Jumbotron } from 'components/jumbotron/jumbotron';
import { ModulePicker } from 'components/module-picker/module-picker';
import { Maintenance } from 'components/maintenance/maintenance';
import { AuthCtx } from 'components/auth/auth-ctx';
import { Text } from 'types';
import { useIntl } from 'react-intl';
import { TextLocation } from '../../enums';
import useFetch from '../../hooks/use-fetch';
import { parseHtml } from '../../utils/parse-html';
import { Loader } from '../../components/loader/loader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    content: {
      padding: '100px 0',
    },
  })
);

export function Homepage({ maintenance }: { maintenance?: boolean }) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { userInfo } = useContext(AuthCtx);

  const { data, loading } = useFetch<Text>(
    `/badatelna/api/paragraph?location=${TextLocation.ENTRY_HOMEPAGE}`
  );
  const htmlString = data?.text ?? '';
  const content = useMemo(() => parseHtml(htmlString, () => {}, classes), [
    htmlString,
    classes,
  ]);

  const jumbotronTitle = userInfo
    ? `${formatMessage({
        id: 'jumbotron.loggedIn.title',
        defaultMessage: 'Vítejte',
      })}, ${userInfo.fullName}`
    : `${formatMessage({
        id: 'jumbotron.notLoggedIn.title',
        defaultMessage: 'Archivní informační systém Univerzity Karlovy',
      })}`;

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Jumbotron title={jumbotronTitle} />
        <Grid container className={classes.content}>
          <Grid item xs={1} md={3} />
          <Grid item xs={10} md={6}>
            {maintenance && <Maintenance />}
            {loading ? (
              <Loader />
            ) : (
              <Typography variant="body1" align="justify">
                {content}
              </Typography>
            )}
          </Grid>
          <Grid item xs={1} md={3} />

          <Grid item xs={1} md={3} />
          <Grid item xs={10} md={6}>
            <ModulePicker />
          </Grid>
          <Grid item xs={1} md={3} />
        </Grid>
      </Grid>
    </Grid>
  );
}
