import { useContext } from 'react';
import { makeStyles, Theme, Typography, Button, Grid } from '@material-ui/core';
import { ModuleRedirect } from 'components/module-redirect/module-redirect';
import { AuthCtx } from 'components/auth/auth-ctx';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 30,
  },
  title: {
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: 15,
    textTransform: 'uppercase',
    letterSpacing: 2,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: 'auto',
    flexWrap: 'wrap',
  },
  or: {
    fontSize: 12,
    fontWeight: 400,
    color: '#BDBDBD',
    margin: '0 20px',
  },
  primaryText: {
    fontWeight: 'bold',
    color: 'white',
    textTransform: 'initial',
  },
}));

export const ModulePicker = () => {
  const classes = useStyles();
  const { userInfo, availableModules } = useContext(AuthCtx);
  const { formatMessage } = useIntl();

  return (
    <div className={classes.root}>
      {userInfo ? (
        <>
          <Typography className={classes.title}>Vyberte si modul</Typography>
          <Grid container justify="center">
            {availableModules.map(({ title, backgroundColor, href }, i) => (
              <Grid item xs={12} sm={3} key={`title-${i}`}>
                <ModuleRedirect
                  text={title}
                  href={href}
                  style={{
                    backgroundColor,
                    margin: '10px',
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <div className={classes.wrapper}>
          <Button
            component="span"
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              window.location.href = 'badatelna';
            }}
          >
            <Typography
              component="span"
              variant="body2"
              className={classes.primaryText}
            >
              {formatMessage({
                id: 'button.toBadatelna',
                defaultMessage: 'Do Badatelny',
              })}
            </Typography>
          </Button>
        </div>
      )}
    </div>
  );
};
