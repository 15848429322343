import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import maintenanceConfig from './maintenance.json';

const useStyles = makeStyles((theme: Theme) => ({
  maintenance: {
    width: '100%',
    backgroundColor: '#FDEEED',
    color: '#262A33',
    textAlign: 'center',
    padding: '10px 20px',
    borderRadius: 5,
    marginBottom: 30,
  },
}));

export const Maintenance = () => {
  const classes = useStyles();

  return (
    <Typography
      className={classes.maintenance}
    >{`Dne ${maintenanceConfig.date} bude od ${maintenanceConfig.from} - ${maintenanceConfig.to} probíhat údržba systému.`}</Typography>
  );
};
