import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { SelectClassKey } from '@material-ui/core/Select';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    label: {
      padding: '0 5px',
      zIndex: 100,
      backgroundColor: '#f6f6f6',
      color: 'black',
      fontSize: 16,
      fontWeight: 'bold',
    },
  })
);

interface Item {
  /**
   *
   */
  id: string;

  /**
   *
   */
  label: string;
}

export interface SelectFieldProps {
  /**
   * Name of input.
   */
  name: string;

  /**
   * Label of input.
   */
  label?: string;

  /**
   * String or number value of input.
   */
  value: string;

  /**
   * Change handler.
   */
  onChange: (value: string) => void;

  /**
   *
   */
  items: Item[];

  /**
   *
   */
  disabled?: boolean;

  /**
   *
   */
  additionalClasses?: Partial<Record<SelectClassKey, string>>;
}

export function SelectField({
  label,
  value,
  onChange,
  items = [],
  disabled,
  additionalClasses,
}: SelectFieldProps) {
  const classes = useStyles();

  /**
   *
   */
  const handleChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const value = event.target.value as string;

    onChange(value);
  };

  return (
    <FormControl variant="outlined" className={classes.root}>
      <InputLabel className={classes.label} disabled={!!disabled} shrink>
        {label}
      </InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        color="secondary"
        error={false}
        spellCheck={true}
        lang="cs"
        disabled={!!disabled}
        classes={additionalClasses}
      >
        {items.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
