import React, { isValidElement } from 'react';
import HtmlToReact from 'html-to-react';
import { deleteWhitespaces } from './delete-whitespaces';
import { normalizeString } from './normalize-string';

type CustomNode = {
  name: string;
  attribs?: {
    href?: string;
  };
};

const isValidNode = () => true;
var processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);

const processingInstructions = (addTab: any, cls: Record<string, string>) => [
  {
    // Custom <a> processing
    shouldProcessNode: function (node: { name?: string }) {
      return node?.name === 'a';
    },
    processNode: ProcessAnchor(cls),
  },
  {
    // Custom <h2> processing
    shouldProcessNode: function (node: { name?: string }) {
      return node?.name === 'h2';
    },
    processNode: CustomH2,
  },
  {
    // Custom <h3> processing
    shouldProcessNode: function (node: { name?: string }) {
      return node?.name === 'h3';
    },
    processNode: CustomH3,
  },
  {
    // Custom <h4> processing
    shouldProcessNode: function (node: { name?: string }) {
      return node?.name === 'h4';
    },
    processNode: CustomH4,
  },
  // everthing else
  {
    shouldProcessNode: function () {
      return true;
    },
    processNode: processNodeDefinitions.processDefaultNode,
  },
];

const htmlToReactParser = new HtmlToReact.Parser();
export const parseHtml = (
  contentRaw: string,
  addTab: any,
  cls: Record<string, string>
) => {
  const reactComponent: JSX.Element = htmlToReactParser.parseWithInstructions(
    contentRaw,
    isValidNode,
    processingInstructions(addTab, cls)
  );

  return reactComponent;
};

function ProcessAnchor(cls: Record<string, string>) {
  return function CustomAnchor(node: CustomNode, children: React.ReactNode) {
    const link = node?.attribs?.href ?? '';

    if (link[0] === '#') {
      return (
        <a href={link} className={cls.anchor}>
          {children}
        </a>
      );
    }

    return (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className={cls.anchor}
      >
        {children}
      </a>
    );
  };
}

/**
 *
 * found this on github https://github.com/sunknudsen/react-node-to-string
 *
 * and modified
 */
const reactNodeToString = function (reactNode: React.ReactNode): string {
  let string = '';
  if (typeof reactNode === 'string') {
    string = reactNode;
  } else if (typeof reactNode === 'number') {
    string = reactNode.toString();
  } else if (reactNode instanceof Array) {
    reactNode.forEach(function (child) {
      string += reactNodeToString(child);
    });
  } else if (isValidElement(reactNode)) {
    string += reactNodeToString(reactNode.props.children);
  }
  return normalizeString(deleteWhitespaces(string.toLocaleLowerCase()));
};

function CustomH2(node: CustomNode, children: React.ReactNode) {
  const id = reactNodeToString(children);
  return <h2 id={id}>{children}</h2>;
}

function CustomH3(node: CustomNode, children: React.ReactNode) {
  const id = reactNodeToString(children);
  return <h3 id={id}>{children}</h3>;
}

function CustomH4(node: CustomNode, children: React.ReactNode) {
  const id = reactNodeToString(children);
  return <h4 id={id}>{children}</h4>;
}
