export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum RoleName {
  NONE = 'NONE',
  ORIGINATOR = 'ORIGINATOR',
  ARCHIVIST = 'ARCHIVIST',
  LEAD_ARCHIVIST = 'LEAD_ARCHIVIST',
  DIGITAL_CONTENT_ADMIN = 'DIGITAL_CONTENT_ADMIN',
  AIS_ADMIN = 'AIS_ADMIN',
  BADATELNA_ADMIN = 'BADATELNA_ADMIN',
  RESEARCHER = 'RESEARCHER',
}

export enum BadatelnaLevel {
  BASIC = 'BASIC',
  ADMIN = 'ADMIN',
}

export enum TextLocation {
  HOMEPAGE = 'HOMEPAGE',
  ENTRY_HOMEPAGE = 'ENTRY_HOMEPAGE',
  CONTACTS = 'CONTACTS',
  INFORMATION = 'INFORMATION',
  BADATELNA = 'BADATELNA',
  GUIDE = 'GUIDE',
}
