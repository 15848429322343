import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import image from './background.jpg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 240,
      backgroundColor: '#626262',
      color: 'white',
      display: 'flex',
      // justifyContent: 'center',
      // alignItems: 'center',
    },
    image: {
      width: '100%',
      height: 240,
      objectFit: 'cover',
      position: 'absolute',
    },
    overlay: {
      width: '100%',
      height: 240,
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: '1rem',
      alignItems: 'center',
      backgroundColor: '#00000055',
    },
  })
);

interface JumbotronProps {
  title: string;
  subtitle?: string;
}

export function Jumbotron({ title, subtitle }: JumbotronProps) {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <img src={image} alt="library" className={classes.image} />
          <div className={classes.overlay}>
            <Typography variant="h1" align="center">
              {title}
            </Typography>
            <Typography variant="h2" align="center">
              {subtitle}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
