import { useEffect, useMemo, useState } from 'react';
import { UserInfo } from 'types';
import { fetchUserInfo } from './auth-api';
import { getAvailableModules } from './auth-utils';

export function useAuth() {
  /**
   * Holds AIS UK userInfo.
   */
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);

  const availableModules = useMemo(() => getAvailableModules(userInfo), [
    userInfo,
  ]);

  const getUser = async () => {
    try {
      const response = await fetchUserInfo();

      setUserInfo(response);
    } catch (e) {
      setUserInfo(undefined);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return {
    userInfo,
    refreshUser: getUser,
    availableModules,
  };
}
