import { BadatelnaLevel, RoleName, SortOrder, TextLocation } from 'enums';

export enum ErrorCode {
  NOT_FOUND = '400',
  FORBIDDEN = '403',
  BAD_REQUEST = '404',
  INTERNAL_ERROR = '500',
}

type errorMap = {
  [key in ErrorCode]: string;
};

export const errorCodeMap: errorMap = {
  // General Error
  400: 'Chyba při zpracování požadavku.',
  403: 'Nemáte oprávnění pracovat s požadovaným objektem.',
  404: 'Položka s vybraným ID nebyla nalezena.',
  500: 'Interní chyba aplikace.',
};

export interface Sort {
  field: string;
  order: SortOrder;
}

export interface Filter {
  field?: string;
  value?: string | string[] | number; // array in case of IDS filter
  operation: string; // todo: might use enum
  ids?: string[];
  filters?: Filter[];
}

export interface Params {
  size?: number;
  offset?: number;
  sort?: Sort[];
  flipDirection?: boolean;
  filters?: Filter[];
  searchAfter?: string;
  include?: string[];
}

export interface Result<T> {
  items?: T[];
  count?: number;
  searchAfter?: string;
  aggregations?: any;
}

export interface DomainObject {
  id: string;
}

export type UserInfo = User;

export interface User extends DomainObject {
  fullName: string;
  existsInElza: boolean;
  existsInVyber: boolean;
  roleName: RoleName;
  badatelnaLevel: BadatelnaLevel;
}

export interface Module {
  title: string;
  backgroundColor: string;
  href: string;
}

export interface Text extends DomainObject {
  location: TextLocation;
  text: string;
}
