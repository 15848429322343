import fetch from 'utils/fetch';
import { UserInfo } from 'types';

/**
 *
 */
export async function fetchUserInfo(): Promise<UserInfo> {
  const response = await fetch(`/esm/api/user/me`, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });

  const json = await response.json();

  return json;
}
