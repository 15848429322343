import 'react-app-polyfill/ie9';
import 'core-js/es/symbol';

import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import { IENotSupported } from 'modules/homepage/ie-not-supported';

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
  typography: {
    fontFamily: ['Open Sans', 'Roboto'].join(','),
    // fontFamily: ['"Montserrat"', 'Roboto'].join(','),
    h1: {
      fontSize: '32px',
      fontWeight: 600,
    },
    h2: {
      fontSize: '30px',
      fontWeight: 600,
    },
    h3: {
      fontSize: '28px',
      fontWeight: 600,
    },
    h4: {
      fontSize: '24px',
      fontWeight: 600,
    },
    h5: {
      fontSize: '22px',
      fontWeight: 600,
    },
    h6: {
      fontSize: '18px',
      fontWeight: 600,
    },
    body1: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '2em',
    },
    body2: {
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '2em',
    },
  },
  palette: {
    primary: {
      main: '#cf2931',
      light: '#ff615b',
      dark: '#96000a',
    },
    secondary: {
      main: '#282b34',
      light: '#50545e',
      dark: '#00000d',
    },
    text: {
      primary: '#000000',
      secondary: '#777777',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*': {
          margin: '0px',
          padding: '0px',
          '-webkit-font-smoothing': 'auto',
          scrollBehavior: 'smooth',
        },
        '*:focus': {
          outline: 'none !important',
        },
        '#root': {
          minHeight: 'calc(100vh - 80px)',
          marginTop: 80,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#f6f6f6',
        },
        '.content': {
          flex: '1 0 auto',
        },
        a: {
          textDecoration: 'none',
        },
        body: {
          overscrollBehavior: 'none',
        },
      },
    },
    MuiListItem: {
      dense: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
});

const isEdge = window.navigator.userAgent.indexOf('Edge') !== -1;
const isIE = window.navigator.userAgent.indexOf('Trident') !== -1 && !isEdge;

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {isIE ? <IENotSupported /> : <App />}
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
