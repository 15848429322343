import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import English from './locale/lang-en.json';
import Czech from './locale/lang-cz.json';
import { MessageFormatElement } from 'intl-messageformat-parser';

export const I18nCtx = createContext<I18nCtxType>(undefined as any);

interface LanguagesMap {
  [key: string]:
    | Record<string, string>
    | Record<string, MessageFormatElement[]>;
}

export interface I18nCtxType {
  locale: string;
  messages: Record<string, string> | Record<string, MessageFormatElement[]>;
  handleSelectLanguage: (e: string) => void;
}

const languagesMap: LanguagesMap = {
  en: English,
  cs: Czech,
};

export function useI18n() {
  const [locale, setLocale] = useState(
    window.localStorage.getItem('lang') || 'cs'
  );

  const [messages, setMessages] = useState({});

  const selectLanguageMessages = useCallback((locale: string) => {
    window.localStorage.setItem('lang', locale);
    setMessages(languagesMap[locale]);
  }, []);

  const handleSelectLanguage = useCallback((locale: string) => {
    const newLocale = locale;

    setLocale(newLocale);
  }, []);

  useEffect(() => {
    selectLanguageMessages(locale);
  }, [locale, selectLanguageMessages]);

  const localeContext: I18nCtxType = useMemo(() => {
    return {
      locale,
      messages,
      handleSelectLanguage,
    };
  }, [handleSelectLanguage, locale, messages]);

  return localeContext;
}
